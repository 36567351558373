import React from 'react';
import { useLocation } from 'react-router-dom';
import "./referral.css";
import person from "../../images/person.png";
import truck from "../../images/truck.png";
import people from "../../images/people.png";
import copy from "../../images/copy.png";
import blackcopy from "../../images/blackcopy.png";
import Footer from "../../component/footer/Footer";

const Referral = () => {
  const location = useLocation();
  const {referral_code } = location.state;
  return (
    <div className="referal-main">
      <div className="referal-top">
        <h1>Refer & Earn!</h1>
        <h2>Stand a chance to win, cash, data or airtime bonuses</h2>
      </div>
      <div className="referal-main-ctn">
        <div className="referal-ctn">
          <h1>HOW IT WORKS</h1>
          <h2>Follow these simple steps</h2>
          <div className="referal-step-main">
            <div className="referal-step">
              <img src={person} width="38px" height="38px" alt="icon" />
              <p>Invite a minimum of five persons to download the pickload user app</p>
            </div>
            <div className="referal-step">
              <img src={people} width="38px" height="38px" alt="icon" />
              <p>Get this five persons to sign up using your unique referral code</p>
            </div>
            <div className="referal-step">
              <img src={truck} width="38px" height="38px" alt="icon" />
              <p>Get them to request for and complete at least 10 daily delivery orders on the app</p>
            </div>
            <div className="referal-step">
              <img src={blackcopy} width="38px" height="38px" alt="icon" />
              <p>Sit back, relax, wait to get your referral reward</p>
            </div>
            <div className="referal-code-ctn">
              <p>Your unique referral code</p>
              <div className="referal-code">
                <p>{referral_code}</p>
                <div className="refer-share">
                  <img
                    src={copy}
                    width="20px"
                    height="20px"
                    alt="icon"
                    onClick={() => navigator.clipboard.writeText(referral_code)}
                  />
                  <p>Copy code</p>
                </div>
              </div>
            </div>
            <a href="/agent-reward">Terms & Conditions apply</a> <br />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Referral;
