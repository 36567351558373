import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import Navbar from "./component/navbar/Navbar";
import Home from "./pages/landingpage/Home";
import Policy from "./pages/policy/Policy";
import TermsOfService from "./pages/service/TermsOfService";
// import Guest from './component/chat/Guest';
import Motion from "./component/framer/Motion";
import FAQs from "./pages/faqs/FAQs";
import Scrolltop from "./component/scroll/Scrolltop";
import Review from "./component/review/Review";
import Tutorial from "./component/videotutorials/Tutorial";
import Download from "./component/downloadapp/Download";
import Login from "./component/signin/Login";
import Referral from "./pages/referral/Referral";
import Reward from "./pages/referral/Reward";
import AgentReward from "./pages/referral/AgentReward";
import OtpPage from "./component/otp/OtpPage";
import Chat from "./component/chat/Chat";
import AirtimeReward from "./pages/referral/AirtimeReward";
import DataReward from "./pages/referral/DataReward";
import CashReward from "./pages/referral/CashReward";
import RewardArrival from "./pages/referral/RewardArrival";
import RewardCongrats from "./pages/referral/RewardCongrats";

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Scrolltop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy" element={<Policy />} />
        <Route path="/Termsandconditions" element={<TermsOfService />} />
        {/* <Route path="/chatbot" element={<Guest />} /> */}
        <Route path="/framer" element={<Motion />} />
        <Route path="/faqs" element={<FAQs />} />
        <Route path="/review" element={<Review />} />
        <Route path="/tutorial" element={<Tutorial />} />
        <Route path="/downloadapp" element={<Download />} />
        <Route path="/referral" element={<Referral />} />
        <Route path="/reward" element={<Reward />} />
        <Route path="agent-reward" element={<AgentReward />} />
        <Route path="/referral_login" element={<Login />} />
        <Route path="/otp-page" element={<OtpPage />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/airtime-reward" element={<AirtimeReward />} />
        <Route path="/data-reward" element={<DataReward />} />
        <Route path="/cash-reward" element={<CashReward />} />
        <Route path="/reward-arrival" element={<RewardArrival />} />
        <Route path="/reward-congrats" element={<RewardCongrats />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
