import React, { useState } from "react";
import "./navbar.css";
import pickloadicon from "../../images/pickloadicon.png";
import pickloadtitle from "../../images/pickloadtitle.png";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="pickload-container">
          <Link to="/" className="nav-link nav-bar-title">
            <img
              src={pickloadicon}
              alt="pickload icon"
              className="nav-title-icon"
            />
            <img
              src={pickloadtitle}
              alt="title"
              className="nav-title-img"
            />
          </Link>
        </div>
        <div className="hamburger-btn">
          <button className="hamburger" onClick={toggleMenu}>
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </button>
        </div>
        <div className="topbar-about">
          <ul className={isOpen ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <Link
                to="https://www.youtube.com/@pickloadlogistics7714"
                target="_blank"
                rel="noreferrer"
                className="nav-link"
              >
              
                {/* <Link to="/tutorial" className="nav-link"> */}
                
                Learn
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/fags" className="nav-link">


                FAQs
              </Link>
            </li>
            <li className="nav-item">


              
              <Link to="/referral_login" className="nav-link">
                Refer a friend
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/downloadapp" className="nav-link">
                <button>Sign Up Now</button>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
