import React, { useEffect, useRef } from "react";
import "../../pages/landingpage/home.css";
import { motion } from "framer-motion";
import pickload from "../../images/pickloaduse.png";
import deliveryboys from "../../images/IMAGE 2.webp";

const Motion = () => {
  const rightDivRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const rightDiv = rightDivRef.current;
      if (rightDiv) {
        const scrollPosition = window.scrollY + window.innerHeight;
        const divPosition = rightDiv.offsetTop + rightDiv.offsetHeight / 2;
        if (scrollPosition > divPosition) {
          rightDiv.style.opacity = 1;
          rightDiv.style.transform = "translateX(0)";
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="fauci-content">
      <div className="motion-container-text">
        <div className="motion-text-img">
          <img src={pickload}  alt="icon" />
        </div>
        <div className="motion-text-ctn">
          <h1>Use Pickload today!</h1>
          <p>and save up to 40% delivery fee</p>
        </div>
      </div>
      <motion.div
        className="homepage-delivery-picture"
        ref={rightDivRef}
        initial={{ opacity: 0, x: "100vw" }} // Initial position, off-screen to the left
        animate={{ opacity: 1, x: 0 }} // Final position, animate to the center of the screen
        transition={{ type: "spring", stiffness: 60, damping: 20 }} // Animation transition
      >
        <img 
          src={deliveryboys} 
          alt="icon" 
          width="70%" 
          height="100%"
        />
      </motion.div>
    </div>
  );
};

export default Motion;
