import React, { useState, useEffect } from 'react';
import './otppage.css';
import { useNavigate, useLocation } from 'react-router-dom';

const OtpPage = () => {
    const [otp, setOtp] = useState(new Array(6).fill(""));
    const [timer, setTimer] = useState(30);
    const [isResendDisabled, setIsResendDisabled] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const { email, phone_no} = location.state;
    const [token, setToken] = useState("");

    useEffect(() => {
        let countdown;
        if (timer > 0) {
            countdown = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        } else {
            setIsResendDisabled(false);
        }

        return () => clearInterval(countdown);
    }, [timer]);

    useEffect(() => {

        if (otp.every((digit) => digit !== "")) {
            verifyOTP();
        }
    }, [otp]);

    const handleChange = (element, index) => {
        if (isNaN(element.value)) return;

        const newOtp = [...otp];
        newOtp[index] = element.value;
        setOtp(newOtp);

        if (element.nextSibling) {
            element.nextSibling.focus();
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace" && !otp[index]) {

            if (e.target.previousSibling) {
                e.target.previousSibling.focus();
            }
        }
    };

    const handleResend = async () => {
        setOtp(new Array(6).fill(""));
        setTimer(30);
        setIsResendDisabled(true);
        try {
            const response = await fetch(
                "https://serverpickload.wl.r.appspot.com/user_referral/request_for_referral_otp",
                {
                    method: "POST",

                    body: JSON.stringify({
                        phone_no: phone_no.slice(1)
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json, text/plain, /",
                    },
                }
            );

            const resultM = await response.json();
            if (response.status === 400) {
                alert("No user with this Phone number exists")
            } else if (response.status === 500) {
                alert("Some error occurred");
            } else {
                alert("Check your email or message for your OTP.");
            }
        } catch (error) {
            console.log(error);
            alert("Some error occurred");
        }
    };

    const verifyOTP = async () => {
        try {
            const response = await fetch(
                "https://serverpickload.wl.r.appspot.com/user_referral/verify_otp_for_referral",
                {
                    method: "POST",
                    body: JSON.stringify({
                        otp: otp.join(""),
                        email: email
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json, text/plain, /",
                    },
                }
            );

            const resultM = await response.json();
            if (resultM.msg === "Incorrect OTP") {
                alert("Incorrect OTP");
                setOtp(new Array(6).fill(""));
            } else if (resultM.msg === "Success") {
                setToken(resultM.token);
                checkRewardStatus(resultM.token, resultM.referral_code);
            }
        } catch (error) {
            console.log(error);
            alert("An error occurred. Please try again.");
        }
    };

    const checkRewardStatus = async (receivedToken, referral_code) => {
        try {
            const response = await fetch(
                "https://serverpickload.wl.r.appspot.com/user_referral/check_reward_eligibility",
                {
                    method: "POST",
                    body: JSON.stringify({
                        token: receivedToken
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json, text/plain, /",
                    },
                }
            );

            const resultM = await response.json();
            console.log(resultM);
            if (resultM.msg === "your're eligible to claim rewards") {
                if (resultM.reward_notifier === "airtime") {
                    navigate("/airtime-reward", { state: { token: receivedToken, referral_code } });
                } else if (resultM.reward_notifier === "data") {
                    navigate("/data-reward", { state: { token: receivedToken, referral_code } });
                } else {
                    navigate("/cash-reward", { state: { token: receivedToken, referral_code } });
                }
            } else if (resultM.msg === "you have benefited already") {
                navigate("/reward-congrats", { state: { eligibility_status: "benefited", referral_code } });
            } 
            
            else {
                navigate("/reward-arrival", { state: { eligibility_status: "on the way", referral_code } });
            }
        } catch (error) {
            console.log(error);
            alert("Some error occurred");
        }
    };

    return (
        <div className="otp-container">
            <h1>Enter OTP</h1>
            <p>We sent a 6-digit code to your email/phone.</p>
            <form>
                <div className="otp-inputs">
                    {otp.map((data, index) => (
                        <input
                            type="number"
                            maxLength="1"
                            key={index}
                            value={data}
                            onChange={(e) => handleChange(e.target, index)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            onFocus={(e) => e.target.select()}
                            className="otp-box"
                        />
                    ))}
                </div>
            </form>
            <p className="resend">
                {isResendDisabled ? (
                    <span>Resend available in {timer}s</span>
                ) : (
                    <span>Didn't receive the code? <a onClick={handleResend} className="resend">Resend</a></span>
                )}
            </p>
        </div>
    );
};

export default OtpPage;
