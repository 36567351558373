import React, { useState } from 'react';
import logsvg from "../../images/LOGIN.webp";
import "./login.css";
import pickloadicon from "../../images/pickloadicon.png";
import pickloadiconprt from "../../images/pickloadiconprt.png";
import pickloadtitle from "../../images/pickloadtitle.png";
import icon from "../../images/icon.png";
import { useNavigate } from 'react-router-dom';
import Footer from '../footer/Footer';

const Login = () => {
    const [phone_no, setPhone] = useState("");
    const [active, setActive] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const requestForOTP = async (e) => {
        e.preventDefault();
        if (!phone_no) {
            alert("Please input your phone number");
            return;
        }
        setActive(true);
        setLoading(true);

        try {
            const response = await fetch(
                "https://serverpickload.wl.r.appspot.com/user_referral/request_for_referral_otp",
                {
                    method: "POST",
                    body: JSON.stringify({
                        phone_no: phone_no.slice(1),
                    }),
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json, text/plain, /",
                    },
                }
            );

            const resultM = await response.json();
            console.log(resultM);
            setLoading(false);

            if (response.status === 400) {
                alert("No user with this phone number exists");
                setActive(false);
            } else if (response.status === 500) {
                alert("Some error occurred");
                setActive(false);
            } else {
                navigate("/otp-page", { state: { email: resultM.email, phone_no } });
            }
        } catch (error) {
            console.log(error);
            alert("Some error occurred");
            setLoading(false);
        }
    };

    const handleChange = (e) => {
        e.preventDefault();
        setPhone(e.target.value);
    };

    const isMobile = window.innerWidth < 768;

    return (
        <div className='login-main'>
            <div className='login-main-ctn'>
                <div className='login-ctn'>
                    <img src={icon} width="16%" height="12%" alt='icon' />
                    <div className='login-props'>
                        <div className='pickload-title-icon-ctn'>
                            <img
                                src={isMobile ? pickloadiconprt : pickloadicon}
                                alt="pickload icon"
                                className="pickload-sign-icon"
                            />
                            <img
                                src={pickloadtitle}
                                width="30%"
                                height="100%"
                                alt="title"
                                className='pickload-title-icon'
                            />
                        </div>
                        <div className='login-text'>
                            <h1>Sign In</h1>
                            <p>Sign in to stay connected.</p>
                            <form>
                                <div className='form-group'>
                                    <label>Phone Number</label>
                                    <input
                                        id='number'
                                        type='text'
                                        name='number'
                                        maxLength={11}
                                        value={phone_no}
                                        onChange={handleChange}
                                    />
                                </div>
                                <button
                                    className='login-btn'
                                    onClick={requestForOTP}
                                    disabled={active || loading}
                                >
                                    {loading ? (
                                        <span className='spinner'></span>
                                    ) : (
                                        "Request for OTP"
                                    )}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
                <div className='login-img'>
                    <img src={logsvg} width="100%" height="100%" alt='icon' />
                </div>
            </div>
            <div className='login-footer'>
                <Footer />
            </div>
        </div>
    );
};

export default Login;
