import React, { useState } from 'react';
import person from "../../images/person.png";
import truck from "../../images/truck.png";
import people from "../../images/people.png";
import Footer from "../../component/footer/Footer";
import congrats from '../../images/congrats.png';
import wallet from '../../images/wallet.png';

const AgentReward = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [showMessage, setShowMessage] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setShowMessage(true); 
        setTimeout(() => {
            setIsOpen(false);
            setShowMessage(false);
        }, 2000); 
    };

  return (
    <div>
        <div className="referal-top">
            <h1>Refer & Earn!</h1>
            <h2>Stand a chance to win, cash, data or airtime bonuses</h2>
        </div>
        <div className='refer-reward'>
            <div className="referal-ctn">
                <h1>HOW IT WORKS</h1>
                <h2>Follow these simple steps</h2>
                <div className="referal-step-main1">
                    <div className="referal-step">
                        <img src={person} width="38px" height="38px" alt="icon" />
                        <p>
                            {" "}
                            Meet your daily and weekly targets to earn exciting rewards.
                        </p>
                    </div>
                    <div className="referal-step">
                        <img src={people} width="38px" height="38px" alt="icon" />
                        <p>
                            Stay focused and track your progress toward each goal.
                        </p>
                    </div>
                    <div className="referal-step">
                        <img src={truck} width="38px" height="38px" alt="icon" />
                        <p>
                            Sit back, relax, and wait for your daily and weekly rewards to arrive.
                        </p>
                    </div>
                    <a href="/Termsandconditions">Terms & Conditions apply</a> <br />
                </div>
            </div>
            <div className='reward-congrats'>
                <img
                    src={congrats}
                    width="70%"
                    height="70%"
                    alt='congratulations'
                    className='congrat-img'
                />
                <p>You just qualified for our daily bonus</p>
                <img
                    src={wallet}
                    width="20%"
                    height="20%"
                    alt='congratulations'
                    className='wallet-img'
                />
                <h2>Cash reward</h2>
                <button onClick={() => setIsOpen(true)}>Claim Reward</button>
                {isOpen && (
                    <div className="reward-modal">
                        <div className="reward-modal-content">
                            <span className="close" onClick={() => setIsOpen(false)}>&times;</span>
                            <h2>User Record Details</h2>
                            <form onSubmit={handleSubmit} className='reward-form'>
                                <label>FullName</label>
                                <input
                                    type="text"
                                    name="name"
                                />
                                <label>Account number</label>
                                <input
                                    type="text"
                                    name="accountNumber"
                                />
                                <label>Bank</label>
                                <input
                                    type="text"
                                    name="bank"
                                />
                                <button type="submit" className='reward-btn'>Submit</button>
                            </form>
                            {showMessage && <div className="congratulations">Details Submitted. Reward on the way!</div>}
                        </div>
                    </div>
                )}
            </div>
        </div>
        <Footer />
    </div>
  )
}

export default AgentReward