import React, { useState, useRef } from "react";
import ReactPlayer from "react-player";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Import FontAwesomeIcon
import { faExpand, faStopCircle } from "@fortawesome/free-solid-svg-icons";
import playicon from "../../images/playicon.png";
// import pickload from "../../video/PickLoad.mp4";
import signup from "../../video/sign up 2k.mp4";
import tracking from "../../video/tracking.mp4";
// import communicate from "../../video/comm with delivery agent.mp4";
// import communicate from "../../video/PickLoad-DA.mp4"
import communicate from '../../video/pp.mp4'
import feedback from "../../video/feedback.mp4";
import help from "../../video/help and support.mp4";
import "./tutorial.css";
import Vector from "../../images/Vector.png";
import video1 from "../../images/video1.png";
import video2 from "../../images/video2.png";
import video3 from "../../images/video3.png";
import video4 from "../../images/video4.png";
import video5 from "../../images/video5.png";
import video6 from "../../images/video6.png";
import video7 from "../../images/be_an_agent.webp";
// import video7 from "../../images/video7.png";

const Tutorial = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const [isHovered4, setIsHovered4] = useState(false);
  const [isHovered5, setIsHovered5] = useState(false);
  const [isHovered6, setIsHovered6] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPlaying1, setIsPlaying1] = useState(false);
  const [isPlaying2, setIsPlaying2] = useState(false);
  const [isPlaying3, setIsPlaying3] = useState(false);
  const [isPlaying4, setIsPlaying4] = useState(false);
  const [isPlaying5, setIsPlaying5] = useState(false);
  const [isPlaying6, setIsPlaying6] = useState(false);
  const videoRef = useRef(null);
  const videoRef1 = useRef(null);
  const videoRef2 = useRef(null);
  const videoRef3 = useRef(null);
  const videoRef4 = useRef(null);
  const videoRef5 = useRef(null);
  const videoRef6 = useRef(null);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleMouseEnter1 = () => {
    setIsHovered1(true);
  };

  const handleMouseLeave1 = () => {
    setIsHovered1(false);
  };

  const handleMouseEnter2 = () => {
    setIsHovered2(true);
  };

  const handleMouseLeave2 = () => {
    setIsHovered2(false);
  };

  const handleMouseEnter3 = () => {
    setIsHovered3(true);
  };

  const handleMouseLeave3 = () => {
    setIsHovered3(false);
  };

  const handleMouseEnter4 = () => {
    setIsHovered4(true);
  };

  const handleMouseLeave4 = () => {
    setIsHovered4(false);
  };

  const handleMouseEnter5 = () => {
    setIsHovered5(true);
  };

  const handleMouseLeave5 = () => {
    setIsHovered5(false);
  };

  const handleMouseEnter6 = () => {
    setIsHovered6(true);
  };

  const handleMouseLeave6 = () => {
    setIsHovered6(false);
  };

  const handlePlay = () => {
    setIsPlaying(true);
    setIsHovered(false); // Hide the thumbnail when video starts playing
  };

  const handlePause = () => {
    setIsPlaying(false);
  };

  const handlePause1 = () => {
    setIsPlaying1(false);
  };

  const handlePause2 = () => {
    setIsPlaying2(false);
  };

  const handlePause3 = () => {
    setIsPlaying3(false);
  };

  const handlePause4 = () => {
    setIsPlaying4(false);
  };

  const handlePause5 = () => {
    setIsPlaying5(false);
  };

  const handlePause6 = () => {
    setIsPlaying6(false);
  };

  const handlePlay1 = () => {
    setIsPlaying1(true);
    setIsHovered1(false); // Hide the thumbnail when video starts playing
  };

  const handlePlay2 = () => {
    setIsPlaying2(true);
    setIsHovered2(false); // Hide the thumbnail when video starts playing
  };

  const handlePlay3 = () => {
    setIsPlaying3(true);
    setIsHovered3(false); // Hide the thumbnail when video starts playing
  };

  const handlePlay4 = () => {
    setIsPlaying4(true);
    setIsHovered4(false); // Hide the thumbnail when video starts playing
  };

  const handlePlay5 = () => {
    setIsPlaying5(true);
    setIsHovered5(false); // Hide the thumbnail when video starts playing
  };

  const handlePlay6 = () => {
    setIsPlaying6(true);
    setIsHovered6(false); // Hide the thumbnail when video starts playing
  };

  const handleFullscreen = () => {
    // Ensure videoRef.current is not null before accessing it
    if (videoRef.current) {
      const videoElement = videoRef.current.getInternalPlayer
        ? videoRef.current.getInternalPlayer()
        : videoRef.current; // Fallback in case getInternalPlayer() is not available

      if (videoElement) {
        if (videoElement.requestFullscreen) {
          videoElement.requestFullscreen();
        } else if (videoElement.mozRequestFullScreen) {
          videoElement.mozRequestFullScreen(); // Firefox
        } else if (videoElement.webkitRequestFullscreen) {
          videoElement.webkitRequestFullscreen(); // Chrome, Safari, Opera
        } else if (videoElement.msRequestFullscreen) {
          videoElement.msRequestFullscreen(); // IE/Edge
        }
      } else {
        console.error("Cannot access the video element.");
      }
    } else {
      console.error("videoRef.current is null.");
    }
  };

  const handleFullscreen1 = () => {
    const videoElement = videoRef1.current.getInternalPlayer(); // Correct ref
    if (videoElement) {
      if (videoElement.requestFullscreen) {
        videoElement.requestFullscreen();
      } else if (videoElement.mozRequestFullScreen) {
        videoElement.mozRequestFullScreen(); // Firefox
      } else if (videoElement.webkitRequestFullscreen) {
        videoElement.webkitRequestFullscreen(); // Chrome, Safari, Opera
      } else if (videoElement.msRequestFullscreen) {
        videoElement.msRequestFullscreen(); // IE/Edge
      }
    }
  };

  const handleFullscreen2 = () => {
    const videoElement = videoRef2.current.getInternalPlayer();
    if (videoElement) {
      if (videoElement.requestFullscreen) {
        videoElement.requestFullscreen();
      } else if (videoElement.mozRequestFullScreen) {
        videoElement.mozRequestFullScreen();
      } else if (videoElement.webkitRequestFullscreen) {
        videoElement.webkitRequestFullscreen();
      } else if (videoElement.msRequestFullscreen) {
        videoElement.msRequestFullscreen();
      }
    }
  };

  const handleFullscreen3 = () => {
    const videoElement = videoRef3.current.getInternalPlayer();
    if (videoElement) {
      if (videoElement.requestFullscreen) {
        videoElement.requestFullscreen();
      } else if (videoElement.mozRequestFullScreen) {
        videoElement.mozRequestFullScreen();
      } else if (videoElement.webkitRequestFullscreen) {
        videoElement.webkitRequestFullscreen();
      } else if (videoElement.msRequestFullscreen) {
        videoElement.msRequestFullscreen();
      }
    }
  };

  const handleFullscreen4 = () => {
    const videoElement = videoRef4.current.getInternalPlayer(); // Access the internal player element
    if (videoElement) {
      // Check and request fullscreen based on the browser
      if (videoElement.requestFullscreen) {
        videoElement.requestFullscreen();
      } else if (videoElement.mozRequestFullScreen) {
        videoElement.mozRequestFullScreen(); // Firefox
      } else if (videoElement.webkitRequestFullscreen) {
        videoElement.webkitRequestFullscreen(); // Chrome, Safari, Opera
      } else if (videoElement.msRequestFullscreen) {
        videoElement.msRequestFullscreen(); // IE/Edge
      }
    }
  };

  const handleFullscreen5 = () => {
    const videoElement = videoRef5.current.getInternalPlayer(); // Access the internal player element
    if (videoElement) {
      if (videoElement.requestFullscreen) {
        videoElement.requestFullscreen();
      } else if (videoElement.mozRequestFullScreen) {
        videoElement.mozRequestFullScreen(); // Firefox
      } else if (videoElement.webkitRequestFullscreen) {
        videoElement.webkitRequestFullscreen(); // Chrome, Safari, Opera
      } else if (videoElement.msRequestFullscreen) {
        videoElement.msRequestFullscreen(); // IE/Edge
      }
    }
  };

  const handleFullscreen6 = () => {
    const videoElement = videoRef6.current.getInternalPlayer();
    if (videoElement) {
      if (videoElement.requestFullscreen) {
        videoElement.requestFullscreen();
      } else if (videoElement.mozRequestFullScreen) {
        videoElement.mozRequestFullScreen();
      } else if (videoElement.webkitRequestFullscreen) {
        videoElement.webkitRequestFullscreen();
      } else if (videoElement.msRequestFullscreen) {
        videoElement.msRequestFullscreen();
      }
    }
  };

  return (
    <div className="tutorial-main-container">
      <div className="tutorial-container">
        <div className="tutorial-header">
          <h3 className="rotated-text">Explainer videos below</h3>
          <h2 style={{ padding: "0 40px" }}>
            More detailed videos on <span>Pickload</span>
          </h2>
        </div>
        <div className="video-main-container">
          <div className="video-sub-container">
            <div className="video-container1">
              <div className="video-details">
                <div
                  className="video-container-props"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  <div className="thumbnail-container">
                    <img
                      src={video1}
                      alt="Video Thumbnail"
                      className="thumbnail"
                    />
                  </div>
                  {isHovered && !isPlaying && (
                    <div className="play-icon" onClick={handlePlay}>
                      <img
                        src={playicon}
                        alt="Play-Icon"
                        className="play-btn"
                      />
                    </div>
                  )}
                  {isPlaying && (
                    <div className="video-play">
                      <ReactPlayer
                        ref={videoRef}
                        className="tutorial-videos"
                        url={signup}
                        playing={true}
                        controls={true}
                        onEnded={() => setIsPlaying(false)}
                      />
                    </div>
                  )}
                  {isPlaying && (
                    <div className="pause-icon" onClick={handlePause}>
                      <FontAwesomeIcon icon={faStopCircle} />
                    </div>
                  )}
                  {isPlaying && (
                    <button
                      className="fullscreen-btn"
                      onClick={handleFullscreen}
                    >
                      <FontAwesomeIcon icon={faExpand} />
                    </button>
                  )}
                </div>
                <p>Sign up</p>
              </div>
              <div className="video-details1">
                <div
                  className="video-container-props"
                  onMouseEnter={handleMouseEnter1}
                  onMouseLeave={handleMouseLeave1}
                >
                  <div className="thumbnail-container">
                    <img
                      src={video2}
                      alt="Video Thumbnail"
                      className="thumbnail"
                    />
                  </div>

                  {isHovered1 && !isPlaying1 && (
                    <div className="play-icon" onClick={handlePlay1}>
                      <img
                        src={playicon}
                        alt="Play Icon"
                        className="play-btn"
                      />
                    </div>
                  )}

                  {isPlaying1 && (
                    <div className="video-play">
                      <ReactPlayer
                        ref={videoRef1} // Use correct ref
                        className="tutorial-videos"
                        url={signup}
                        playing={true}
                        controls={true}
                        onEnded={() => setIsPlaying1(false)}
                      />
                    </div>
                  )}

                  {isPlaying1 && (
                    <div className="pause-icon" onClick={handlePause1}>
                      <FontAwesomeIcon icon={faStopCircle} />
                    </div>
                  )}

                  {isPlaying1 && (
                    <button
                      className="fullscreen-btn"
                      onClick={handleFullscreen1}
                    >
                      <FontAwesomeIcon icon={faExpand} />
                    </button>
                  )}
                </div>
                <p>Place your order</p>
              </div>
              <div className="video-details2">
                <div
                  className="video-container-props"
                  onMouseEnter={handleMouseEnter2}
                  onMouseLeave={handleMouseLeave2}
                >
                  <div className="thumbnail-container">
                    <img
                      src={video3}
                      alt="Video Thumbnail"
                      className="thumbnail"
                    />
                  </div>
                  {isHovered2 && !isPlaying2 && (
                    <div className="play-icon" onClick={handlePlay2}>
                      <img
                        src={playicon}
                        alt="Play-Icon"
                        className="play-btn"
                      />
                    </div>
                  )}
                  {isPlaying2 && (
                    <div className="video-play">
                      <ReactPlayer
                        ref={videoRef2}
                        className="tutorial-videos"
                        url={tracking}
                        playing={true}
                        controls={true}
                        onEnded={() => setIsPlaying2(false)}
                      />
                    </div>
                  )}
                  {isPlaying2 && (
                    <div className="pause-icon" onClick={handlePause2}>
                      <FontAwesomeIcon icon={faStopCircle} />
                    </div>
                  )}
                  {isPlaying2 && (
                    <button
                      className="fullscreen-btn"
                      onClick={handleFullscreen2}
                    >
                      <FontAwesomeIcon icon={faExpand} />
                    </button>
                  )}
                </div>
                <p>Track your order</p>
              </div>
            </div>
            <div className="video-container2">
              <div className="video-details3">
                <div
                  className="video-container-props"
                  onMouseEnter={handleMouseEnter3}
                  onMouseLeave={handleMouseLeave3}
                >
                  <div className="thumbnail-container">
                    <img
                      src={video4}
                      alt="Video Thumbnail"
                      className="thumbnail"
                    />
                  </div>
                  {isHovered3 && !isPlaying3 && (
                    <div className="play-icon" onClick={handlePlay3}>
                      <img
                        src={playicon}
                        alt="Play-Icon"
                        className="play-btn"
                      />
                    </div>
                  )}
                  {isPlaying3 && (
                    <div className="video-play">
                      <ReactPlayer
                        ref={videoRef3}
                        className="tutorial-videos"
                        url={communicate}
                        playing={true}
                        controls={true}
                        onEnded={() => setIsPlaying3(false)}
                      />
                    </div>
                  )}
                  {isPlaying3 && (
                    <div className="pause-icon" onClick={handlePause3}>
                      <FontAwesomeIcon icon={faStopCircle} />
                    </div>
                  )}
                  {isPlaying3 && (
                    <button
                      className="fullscreen-btn"
                      onClick={handleFullscreen3}
                    >
                      <FontAwesomeIcon icon={faExpand} />
                    </button>
                  )}
                </div>
                <p>
                  Communicate with <br />
                  delivery agent
                </p>
              </div>
              <div className="video-details4">
                <div
                  className="video-container-props"
                  onMouseEnter={handleMouseEnter4}
                  onMouseLeave={handleMouseLeave4}
                >
                  <div className="thumbnail-container">
                    <img
                      src={video5}
                      alt="Video Thumbnail"
                      className="thumbnail"
                    />
                  </div>

                  {isHovered4 && !isPlaying4 && (
                    <div className="play-icon" onClick={handlePlay4}>
                      <img
                        src={playicon}
                        alt="Play Icon"
                        className="play-btn"
                      />
                    </div>
                  )}

                  {isPlaying4 && (
                    <div className="video-play">
                      <ReactPlayer
                        ref={videoRef4} // Assign ref to ReactPlayer
                        className="tutorial-videos"
                        url={feedback}
                        playing={true}
                        controls={true}
                        onEnded={() => setIsPlaying4(false)}
                      />
                    </div>
                  )}

                  {isPlaying4 && (
                    <div className="pause-icon" onClick={handlePause4}>
                      <FontAwesomeIcon icon={faStopCircle} />
                    </div>
                  )}

                  {isPlaying4 && (
                    <button
                      className="fullscreen-btn"
                      onClick={handleFullscreen4}
                    >
                      <FontAwesomeIcon icon={faExpand} />
                    </button>
                  )}
                </div>
                <p>Feedback</p>
              </div>
              <div className="video-details5">
                <div
                  className="video-container-props"
                  onMouseEnter={handleMouseEnter5}
                  onMouseLeave={handleMouseLeave5}
                >
                  <div className="thumbnail-container">
                    <img
                      src={video6}
                      alt="Video Thumbnail"
                      className="thumbnail"
                    />
                  </div>

                  {isHovered5 && !isPlaying5 && (
                    <div className="play-icon" onClick={handlePlay5}>
                      <img
                        src={playicon}
                        alt="Play Icon"
                        className="play-btn"
                      />
                    </div>
                  )}

                  {isPlaying5 && (
                    <div className="video-play">
                      <ReactPlayer
                        ref={videoRef5} // Correct ref for ReactPlayer
                        className="tutorial-videos"
                        url={help}
                        playing={true}
                        controls={true}
                        onEnded={() => setIsPlaying5(false)}
                      />
                    </div>
                  )}

                  {isPlaying5 && (
                    <div className="pause-icon" onClick={handlePause5}>
                      <FontAwesomeIcon icon={faStopCircle} />
                    </div>
                  )}

                  {isPlaying5 && (
                    <button
                      className="fullscreen-btn"
                      onClick={handleFullscreen5}
                    >
                      <FontAwesomeIcon icon={faExpand} />
                    </button>
                  )}
                </div>
                <p>Help & support</p>
              </div>
            </div>
          </div>
          <div className="video-details7">
            <div className="vector-class-icon">
              <img src={Vector} alt="icon" className="vector-tutorial-icon" />
            </div>
            <div
              className="video-container-props7"
              onMouseEnter={handleMouseEnter6}
              onMouseLeave={handleMouseLeave6}
            >
              <div className="thumbnail-container7">
                <img
                  src={video7}
                  alt="Video Thumbnail"
                  className="thumbnail7"
                />
              </div>

              {isHovered6 && !isPlaying6 && (
                <div className="play-icon7" onClick={handlePlay6}>
                  <img src={playicon} alt="Play Icon" className="play-btn7" />
                </div>
              )}

              {isPlaying6 && (
                <div className="video-play7">
                  <ReactPlayer
                    ref={videoRef6} // Ensure this matches the handler ref
                    className="tutorial-videos7"
                    url={communicate}
                    playing={true}
                    controls={true}
                    onEnded={() => setIsPlaying6(false)}
                  />
                </div>
              )}

              {isPlaying6 && (
                <div className="pause-icon" onClick={handlePause6}>
                  <FontAwesomeIcon icon={faStopCircle} />
                </div>
              )}

              {isPlaying6 && (
                <button className="fullscreen-btn7" onClick={handleFullscreen6}>
                  <FontAwesomeIcon icon={faExpand} />
                </button>
              )}
            </div>
            <p>
              Become a<br />
              delivery agent
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tutorial;
