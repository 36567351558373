import React from 'react';
import './operation.css'
import housemovement from '../../images/house_movement.webp';
import parceldelivery from '../../images/parcel_delivery.webp';
import ourservicebg from '../../images/ourservicebg.webp';
import operationprotrait from '../../images/operationpotrait.webp';

const Operation = () => {
  
  return (
    <div className='operation-main-container'>
        <div className='operation-sub-container'>
            <div className='operation-container'>
                <img 
                    src={ourservicebg}
                    alt='icon'
                />
                <div className='operation-responsive'>
                    <img 
                        src={operationprotrait}
                        alt='icon'
                        className='operation-potrait'
                    />
                </div>
                <div className='operation-content'>
                    <h2>Our Services</h2>
                    <p> <span>Pickload </span>offers a wide range of delivery services 
                        that cater for different needs, ranging from the transportation
                        of small parcels and items to the relocation of entire households.
                        We prioritize our users satisfaction by offering both on-demand/instant 
                        delivery services and scheduled delivery services.
                    </p>
                    <div className='operation-image'>
                        <div className='operation-img1'>
                          <h2>PARCEL DELIVERY</h2>
                            <img
                                src={parceldelivery}
                                alt='icon'
                                className='operate-img-par'
                            />
                        </div>
                        <div className='operation-img1'>
                            <h2>HOUSE MOVEMENT</h2>
                            <img
                                src={housemovement}
                                alt='icon'
                                className='operate-img-par'
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Operation