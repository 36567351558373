import React, { useRef, useEffect, useState } from "react";

import "./testimonial.css";
import testimonialicon from "../../images/testimonialicon.png";
import Ellipse1 from "../../images/Ellipse1.png";
import Ellipse2 from "../../images/Ellipse2.png";
import Ellipse3 from "../../images/Ellipse3.png";
import Ellipse4 from "../../images/Ellipse4.png";
import Ellipse5 from "../../images/Ellipse5.png";
import rate1 from "../../images/rate1.png";
import rate2 from "../../images/rate2.png";
import ReactPlayer from "react-player/lazy";
import playicon from "../../images/playicon.png";

const Testimonial = ({ videos }) => {
  const [playing, setPlaying] = useState(false);
  const playerRef = useRef(null);
  const [current_thumbnail_url, setCurrentThumbnailUrl] = useState("");
  const [current_video_url, setCurrentVideoUrl] = useState("");
  const [current_username, setCurrentUsername] = useState("");
  const [current_user_occupation, setCurrentOccupation] = useState("");

  const togglePlay = () => {
    setPlaying(!playing);
    // setShowMainButtons(true);
  };

  const handlePlay = () => {
    // console.log("playing....");
    setPlaying(true);
  };

  const handlePause = () => {
    // console.log("stopped playing....");
    setPlaying(false);
  };
  const [isMuted, setIsMuted] = useState(false);

  const handleToggleMute = () => {
    setIsMuted(!isMuted);
  };

  // console.log(videos);

  useEffect(() => {
    setCurrentThumbnailUrl(videos[0]?.thumbnail_url);
    setCurrentVideoUrl(videos[0]?.video_url);
    setCurrentUsername(videos[0]?.username ?? "...");
    setCurrentOccupation(videos[0]?.user_occupation ?? "...");
  }, []);

  return (
    <div className="testimonial-main-container">
      <div className="testimonial-container">
        <div className="testimonial-col-1">
          <div className="testimonial-box1">
            <img
              src={testimonialicon}
              alt="icon"
              className="testimonial-icon"
            />

            <div className="container1">
              <div className="profile-details1">
                <img src={Ellipse5} alt="icon" className="ellipse-icon" />
                <div className="test-profile-about1">
                  <h2>Hillary Abeke</h2>
                  <p>Trader</p>
                </div>
              </div>
              <p>
                Before the app, managing my business logistics was tough. Now,
                it's easy. With its user-friendly design and powerful features,
                I save time and money. Couldn't be happier!
              </p>
              <img src={rate1} alt="icon" className="rate-icon" />
            </div>
          </div>
          <div className="container2">
            <div className="profile-details2">
              <img src={Ellipse1} alt="icon" className="ellipse-icon" />
              <div className="test-profile-about2">
                <h2>Anna Chijioke</h2>
                <p>Sales Manager</p>
              </div>
            </div>
            <p>
              Choosing Pickload for my delivery needs has been a game-changer
              for my business. Their seamless platform, coupled with their
              dedicated team of drivers, has elevated the delivery experience
              for both me and my customers
            </p>
            <img src={rate2} alt="icon" className="rate-icon" />
          </div>
          <div className="container3">
            <div className="profile-details3">
              <img src={Ellipse2} alt="icon" className="ellipse-icon" />
              <div className="test-profile-about3">
                <h2>Mary Igwokhai</h2>
                <p>logistics Agent</p>
              </div>
            </div>
            <p>
              Pickload is the delivery service I've been searching for. From the
              moment I started using their platform, everything became smoother
              and more efficient. The convenience of scheduling pickups and
              tracking shipments in real-time has saved me countless hours.
            </p>
            <img src={rate1} alt="icon" className="rate-icon" />
          </div>
        </div>
        <div className="testimonial-col-2">
          <div className="container4">
            <div className="profile-details4">
              <img src={Ellipse3} alt="icon" className="ellipse-icon" />
              <div className="test-profile-about4">
                <h2>John Raphael</h2>
                <p>Enterpreneur</p>
              </div>
            </div>
            <p>
              As an ecommerce entrepreneur, delivery is one of the most
              important aspect of my business. That's why I was thrilled to
              discover this logistics app. It's transformed the way I manage my
              orders and deliveries, with real-time tracking, automatic
              notifications, and seamless integration..
            </p>
            <img src={rate2} alt="icon" className="rate-icon" />
          </div>
          <div className="testimonial-videos-parent">
            <div className="testimonial-video-frame">
              <ReactPlayer
                ref={playerRef}
                url={current_video_url}
                light={current_thumbnail_url}
                // url={main_promo?.video_url}
                // light={main_promo?.thumbnail_url}
                width="100%"
                height="100%"
                playing={playing}
                onPlay={handlePlay}
                onPause={handlePause}
                loop
                muted={isMuted} // Mute the video
                playIcon={
                  <img
                    src={playicon}
                    alt="icon"
                    className="testimonial-video-playicon"
                    onClick={togglePlay}
                  />
                }
              />
            </div>
            <div className="testimonial-videos-sub-parent">
              <>
                {videos.map((testimonial, index) => (
                  <div key={index} className="testimonial-video-sub">
                    <img
                      src={testimonial.thumbnail_url}
                      key={index}
                      alt="icon"
                      onClick={() => {
                        setCurrentThumbnailUrl(testimonial.thumbnail_url);
                        setCurrentVideoUrl(testimonial.video_url);
                        setCurrentUsername(testimonial.username ?? "...");
                        setCurrentOccupation(
                          testimonial.user_occupation ?? "..."
                        );
                        handlePlay();
                      }}
                    />
                  </div>
                ))}
                <div className="testimonial-videos-sub-profile">
                  <span className="testimonial-videos-sub-profile-name">
                    {current_username}
                  </span>
                  <br />
                  <span className="testimonial-videos-sub-profile-occupation">
                    {current_user_occupation}
                  </span>
                </div>
              </>
            </div>
          </div>
          <div className="container6">
            <div className="profile-details6">
              <img src={Ellipse4} alt="icon" className="ellipse-icon" />
              <div className="test-profile-about6">
                <h2>Olumide Daramola</h2>
                <p>Trader</p>
              </div>
            </div>
            <p>
              Pickload has truly been a game-changer for my business. As someone
              who heavily relies on timely deliveries, I can't express enough
              how invaluable this service has been. Their platform is intuitive,
              their drivers are reliable, and their tracking system provides
              peace of mind like no other
            </p>
            <img src={rate1} alt="icon" className="rate-icon" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Testimonial;
